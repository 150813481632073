import React from "react";
import './ConfirmPasswordRecoveryForm.module.scss';
import classes from './ConfirmPasswordRecoveryForm.module.scss.json';

const ConfirmPasswordRecoveryForm = ({ email }) => {
    return (
        <div id={classes['confirm-password-container']}>
            <p>Un email vient de vous être envoyé sur l’adresse mail {email}.</p>
            <p>Veuillez consulter celle-ci et vérifier dans vos spams si nécessaire.</p>
        </div>
    );
};

export default ConfirmPasswordRecoveryForm;