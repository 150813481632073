import { faEnvelope, faUnlock, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { PasswordRecoveryForm } from "../apps/components/PasswordRecoveryForm/PasswordRecoveryForm";
import { LoginForm } from "../apps/components/LoginForm/LoginForm";
import CustomModal from "../apps/components/CustomModal/CustomModal";
import { useStyleContext } from "./useStyleContext";
import ConfirmPasswordRecoveryForm from "../apps/components/ConfirmPasswordRecoveryForm/ConfirmPasswordRecoveryForm";

const customerData = customer_data ?? {};
const CustomerContext = React.createContext(customerData);

const CustomerProvider = ({ children }) => {
    const { configs, booking_module_setting } = useStyleContext();
    const [isLog, setIsLog] = React.useState(customerData.is_log === true ?? false);
    const [customer, setCustomer] = React.useState({
        first_name: customerData.customer?.user?.first_name ?? '',
        last_name: customerData.customer?.user?.last_name ?? '',
        email: customerData.customer?.user?.email ?? '',
        phone_number: customerData.customer?.user?.phone_number ?? '',
        address: customerData.customer?.user?.address?.street_line_one ?? '',
        postcode: customerData.customer?.user?.address?.postcode ?? '',
        city: customerData.customer?.user?.address?.city ?? '',
        district: customerData.customer?.user?.address?.district ?? '',
        // TODO: check if is territoty or country
        country: customerData.customer?.user?.address?.country ?? '',
        territory: customerData.customer?.user?.address?.territory ?? '',
        birthday: customerData.customer?.user?.birthday ?? '',
        birth_city: customerData.customer?.user?.birth_city ?? '',
        nationnality: customerData.customer?.user?.nationnality ?? '',
    });
    const [company, setCompany] = React.useState({
        company_siren: customerData.customer?.user?.company_siren ?? '',
        company_vat_number: customerData.customer?.user?.company_vat_number ?? '',
        company_name: customerData.customer?.user?.first_name ?? '',
        company_email: customerData.customer?.user?.email ?? '',
        company_phone_number: customerData.customer?.user?.phone_number ?? '',
        company_address: customerData.customer?.user?.address?.street_line_one ?? '',
        company_postcode: customerData.customer?.user?.address?.postcode ?? '',
        company_city: customerData.customer?.user?.address?.city ?? '',
        customers: customerData.customer?.user?.customers ?? [],
        has_motor_vehicle: customerData.customer?.user?.has_motor_vehicle ?? false,
    });
    const [drivingLicense, setDrivingLicense] = React.useState(customerData.customer?.user?.driving_license ?? {});
    const [recovery, setRecovery] = React.useState(customerData.recovery ?? false);
    const [email, setEmail] = React.useState(customerData.email ?? '');
    const [tmpMail, setTmpMail] = React.useState('');
    const [passwordRecoverySent, setPasswordRecoverySent] = React.useState(false);
    const [openConfirmPasswordRecovery, setOpenModalConfirmRecovery] = React.useState(false);
    const [bookings, setBookings] = React.useState([]);
    const [isLogin, setIsLogin] = React.useState(false);
    const [openModalLogin, setOpenModalLogin] = React.useState(false);
    const [openModalRecovery, setOpenModalRecovery] = React.useState(false);
    const isNewCustomer = customerData.is_new ?? false;
    const isEnabled = booking_module_setting?.['active_booking_app'] && 
        booking_module_setting?.['active_booking_app'] === true && 
        configs?.["content_config-active_customer_space"] && 
        configs?.["content_config-active_customer_space"] === true;
    // if data.user.is_pro then user is logged
    // if data.is_pro then booking was created with pro account but user is logged as guest
    const isPro = customerData.customer?.user?.is_pro ?? customerData.is_pro ?? false;
    const token = customerData.token ?? '';

    const formatRawCustomer = (rawCustomer) => {
        return {
            first_name: rawCustomer?.first_name ?? '',
            last_name: rawCustomer?.last_name ?? '',
            email: rawCustomer?.email ?? '',
            phone_number: rawCustomer?.phone_number ?? '',
            address: rawCustomer?.address?.street_line_one ?? '',
            postcode: rawCustomer?.address?.postcode ?? '',
            city: rawCustomer?.address?.city ?? '',
            district: rawCustomer?.address?.district ?? '',
            // TODO: check if is territoty or country
            country: rawCustomer?.address?.country ?? '',
            territory: rawCustomer?.address?.territory ?? '',
            birthday: rawCustomer?.birthday ?? '',
            birth_city: rawCustomer?.birth_city ?? '',
            nationnality: rawCustomer?.nationnality ?? ''
        };
    }

    const formatAddress = (data, isCompany) => {
        if (isCompany ? data.company_address : data.address) {
            const tmp = isCompany ? data.company_address : data.address;

            isCompany ? delete data.company_address : delete data.address;

            data.address = {};
            data.address.street_line_one = tmp;
        } else {
            data.address = {};
        }

        if (isCompany ? data.company_postcode : data.postcode) {
            data.address.postcode = isCompany ? data.company_postcode : data.postcode;

            isCompany ? delete data.company_postcode : delete data.postcode;
        }

        if (isCompany ? data.company_city : data.city) {
            data.address.city = isCompany ? data.company_city : data.city;

            isCompany ? delete data.company_city : delete data.city;
        }

        if (isCompany) { return data; }

        if (data.district) {
            data.address.district = data.district;

            delete data.district;
        }

        if (data.country) {
            data.address.country = data.country;

            delete data.country;
        }

        if (data.territory) {
            data.address.territory = data.territory;

            delete data.territory;
        }

        return data;
    }

    const getCustomer = async () => {
        try {
            const req = await fetch('/api/customer', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }});
            
                if (req.status === 200) {
                    const data = await req.json();
                    setCustomer(formatRawCustomer(data.data.user));
                    
                    if (!isLog) {
                        setIsLog(true);
                    }
                }

        } catch (error) {
            console.error(error);
        }
    }

    const setLogin = ({ user, bookings }) => {
        const tmpCustomer = formatRawCustomer(user);

        setCustomer(tmpCustomer);
        setBookings(bookings);
        setIsLogin(true);
    }

    React.useEffect(() => {
        if (customerData.customer?.bookings) {
            for (const booking in customerData.customer.bookings) {
                setBookings((prev) => [...prev, customerData.customer.bookings[booking]]);
            }
        }

        return () => setBookings([]);
    }, []);

    React.useEffect(() => {
        if (!isLogin) { return; }
        if (JSON.stringify(customer) === '{}') { return; }

        setIsLog(true);
        setIsLogin(false);
    }, [isLogin]);

    React.useEffect(() => {
        if (!passwordRecoverySent) { return; }

        setOpenModalConfirmRecovery(true);

        const delay = setTimeout(() => {
            setOpenModalConfirmRecovery(false);
            setPasswordRecoverySent(false);
            setTmpMail('');
        }, 5000);

        return () => clearTimeout(delay);
    }, [passwordRecoverySent]);

    React.useEffect(() => {
        if (!openModalRecovery) { return; }
        
        if (openModalLogin) {
            setOpenModalLogin(false);
        }
    }, [openModalRecovery]);
    
    return (
        <CustomerContext.Provider
            value={{
                setIsLog,
                setLogin,
                setCustomer,
                setRecovery,
                setEmail,
                setBookings,
                formatRawCustomer,
                setOpenModalLogin,
                setOpenModalRecovery,
                setDrivingLicense,
                formatAddress,
                getCustomer,
                openModalLogin,
                openModalRecovery,
                isLog,
                customer,
                recovery,
                email,
                bookings,
                token,
                isEnabled,
                drivingLicense,
                isPro,
                company,
                isNewCustomer,
                setCompany
            }}
        >
            {children}
            {!isLog && (
                <>
                    <CustomModal
                        children={(
                            <LoginForm
                                passwordRecovery={(bool) => setOpenModalRecovery(!!bool)}
                                onSuccess={() => window.location.reload()}
                            />
                        )}
                        isOpen={openModalLogin}
                        onClose={() => setOpenModalLogin(false)}
                        title={"Connexion à votre compte"}
                        subtitle={"Saisissez vos informations afin d’accéder à votre compte"}
                        icon={faUserCircle}
                    />
                    <CustomModal
                        children={(
                            <PasswordRecoveryForm
                                onSuccess={(email) => {
                                    setOpenModalRecovery(false);
                                    setPasswordRecoverySent(true);
                                    setTmpMail(email);
                                }}
                            />
                        )}
                        isOpen={openModalRecovery}
                        onClose={() => setOpenModalRecovery(false)}
                        title={"Mot de passe oublié"}
                        subtitle={"Vous avez oublié votre mot de passe ?\nSaisissez votre email ci-dessous, nous vous enverrons un lien afin de ré-initialiser votre mot de passe."}
                        icon={faUnlock}
                    />
                    <CustomModal
                        children={<ConfirmPasswordRecoveryForm email={tmpMail} />}
                        isOpen={openConfirmPasswordRecovery}
                        onClose={() => setOpenModalConfirmRecovery(false)}
                        title={"Un email vous a été envoyé"}
                        subtitle={""}
                        icon={faEnvelope}
                    />
                </>
            )}
        </CustomerContext.Provider>
    );
}

const useCustomer = () => useContext(CustomerContext);

export { CustomerProvider, useCustomer };