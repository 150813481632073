import React from "react";
import cx from "classnames";
import classes from "./CustomModal.module.scss.json";
import './CustomModal.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CustomModal = ({ children, onClose = () => { }, isOpen = false, title = '', subtitle = '', icon }) => {
    const [show, setShow] = React.useState(isOpen);
    const [firstRender, setFirstRender] = React.useState(true);
    const modalRef = React.useRef(null);
    const overlayRef = React.useRef(null);

    const onHide = () => {
        setShow(false);
        onClose();
    }

    // slide left to right with fade in
    const startAnimation = () => {
        overlayRef.current.style.display = `flex`;
        overlayRef.current.style.display = `${classes['fade-in']} 0.25s forwards ease-in-out`;

        modalRef.current.style.display = `flex`;
        modalRef.current.style.animation = `${classes['slide-in']} 0.25s forwards ease-in-out`;
    }

    // slide right to left with fade out
    const endAnimation = () => {
        overlayRef.current.style.animation = `${classes['fade-out']} 0.25s forwards ease-in-out`;
        modalRef.current.style.animation = `${classes['slide-out']} 0.25s forwards ease-in-out`;

        const delay = setTimeout(() => {
            overlayRef.current.style.display = `none`;
            modalRef.current.style.display = `none`;

            setShow(null);
        }, 300);

        return () => clearTimeout(delay);
    }

    React.useEffect(() => {
        const keyEvent = window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                onHide();
            }
        });

        return () => window.removeEventListener('keydown', keyEvent);
    }, []);

    React.useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }

        setShow(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        if (show) {
            startAnimation();
        } else if (show === false) {
            endAnimation();
        }
    }, [show]);

    if (show === null) return null;

    return (
        <div ref={overlayRef} className={classes['custom-modal-parent']} onClick={(e) => {
            if (e.target === overlayRef.current) {
                e.stopPropagation();
                onHide();
            }
        }}>
            <div ref={modalRef} className={classes["custom-modal"]}>
                <div className={classes["custom-modal-content"]}>
                    {/* NOTE: need it ? */}
                    <div className={classes["custom-modal-header"]}>
                        <button type="button" className="btn" onClick={onHide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className={classes["custom-modal-title"]}>
                        <div id={classes['custom-modal-icon-container']}>
                            <FontAwesomeIcon icon={icon} />
                        </div>
                        {title && (<span id={classes["title-element"]}>{title}</span>)}
                        {subtitle && (<p id={classes["subtitle-element"]} dangerouslySetInnerHTML={{ __html: subtitle }} />)}
                    </div>
                    <div className={classes["custom-modal-body"]}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomModal;