import React from "react";
import { ReturnToHomePage } from "../CustomerMenu/CustomerMenu";
import { useStyleContext } from "../../../customHooks/useStyleContext";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from '../CustomerMenu/CustomerMenu.module.scss.json';

const DefaultMenu = ({ hideLink = false, hideReturn = false }) => {
    const styleContext = useStyleContext();
    const links = styleContext?.header?.links || [];
    const collapseRef = React.useRef(null);

    const getUrlFromName = (name, url = '') => {
        switch (name) {
            case 'header-links-booking':
                return url && url != '/reservation' ? url : '/reservation';
            case 'header-links-vehicles':
                return url && url != '/location-voitures' ? url : '/location-voitures';
            case 'header-links-contact':
                return url && url != '/contact' ? url : '/contact';
            default:
                return url && url != '/' ? url : '/';
        }
    }

    const activeBooking = styleContext.booking_module_setting?.['active_booking_app'] ?? null;
    const textColorIsDefined = styleContext?.header?.configs?.['config-text_color'] ?? null;
    const headerColorIsDefined = styleContext?.header?.configs?.['config-background_color'] ?? null;
    const linkColor = textColorIsDefined ? "" : "c-link";
    // NOTE: if textColorIsDefined is true, use it to insert inside style attribute
    const linkClass = linkColor + " pointer medium m-0";

    const filteredLinks = [...links]
        ?.sort((a, b) => a.display_order - b.display_order)
        ?.filter((link) => {
            if (link.name == 'header-links-booking') {
                return activeBooking == null ? false : activeBooking;
            }

            return link.label != "";
        });

    if (activeBooking) {
        const updateHeaderLink = filteredLinks?.find((a) => a.name == 'header-links-booking');

        if (updateHeaderLink) {
            updateHeaderLink.label = updateHeaderLink.label || 'Ma réservation';
        }
    }

    // FEATURE: add smooth close (.collapse > .collapse .show > .collapsing > .collapse)
    React.useEffect(() => {
        const handleClick = (e) => {
            if (collapseRef.current && !collapseRef.current.contains(e.target) && collapseRef.current.classList.contains('show')) {
                collapseRef.current.classList.remove('show');
            }
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, []);

    return (
        <div className={classes['safe-area']}>
            {!hideReturn && (window.location.pathname.indexOf('check-in') == -1) && (
                <ReturnToHomePage />
            )}
            {!hideLink && (
                <>
                    <div className="d-none d-md-flex flex-row justify-content-between" style={{ marginLeft: 'auto' }}>
                        {filteredLinks?.map((link, index) => (
                            <div
                                key={index}
                                className={"px-4 d-flex align-items-center medium"}
                                onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                            >
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >
                                    {link.label}
                                </span>
                            </div>
                        ))}
                    </div>

                    <div className={"d-flex d-md-none " + classes["customer-menu-container"]} style={{ marginLeft: 'auto' }}>
                        <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-menu"} aria-expanded="false" aria-controls={"collapse-menu"}>
                            <FontAwesomeIcon icon={faBars} color="var(--c-main)" />
                        </button>
                    </div>
                    <div {...headerColorIsDefined && { style: { backgroundColor: headerColorIsDefined } }} ref={collapseRef} className={"collapse mt-1 " + classes["collapseCustomerMenu"]} id={"collapse-menu"}>
                        {filteredLinks.map((link, index) => (
                            <div
                                key={index}
                                className={classes["collapse-item"]}
                                onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                            >
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >
                                    {link.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default DefaultMenu;