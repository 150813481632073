import React from "react";
import { useCustomer } from "../../../customHooks/useCustomer";
import CustomerMenu from "../CustomerMenu/CustomerMenu";
import DefaultMenu from "../DefaultMenu/DefaultMenu";

const MenuContainer = ({ hideLink = false, hideReturn = false }) => {
    const { isEnabled } = useCustomer();

    if (isEnabled) {
        return <CustomerMenu hideLink={hideLink} hideReturn={hideReturn} />;
    }

    return <DefaultMenu hideLink={hideLink} hideReturn={hideReturn} />;
}

export default MenuContainer;