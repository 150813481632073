import React from 'react';
import './LoginForm.module.scss';
import classes from './LoginForm.module.scss.json';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';
import { useCustomer } from '../../../customHooks/useCustomer';

export const LoginForm = ({ passwordRecovery = (_) => { }, onSuccess = () => {} }) => {
    const customerContext = useCustomer();
    const { setLogin } = customerContext;
    const [isLoading, setIsLoading] = React.useState(false);
    const [userForm, setUserForm] = React.useState({
        email: '',
        password: ''
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [tooltip, showTooltip] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const req = await fetch('/api/customer/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userForm.email,
                    password: userForm.password
                })
            });

            if (req.status === 200) {
                const res = await req.json();

                if (res.success) {
                    setLogin(res.data);
                    onSuccess();
                } else {
                    setError('Une erreur est survenue');
                }
            } else {
                switch (req.status) {
                    case 401 || 403:
                        setError('Email et/ou mot de passe incorrect');
                        break;
                    default:
                        setError('Une erreur est survenue');
                        break;
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        if (error !== '') {
            showTooltip(true);
        }
    }, [error]);

    React.useEffect(() => {
        if (tooltip === false) {
            setError('');
        }
    }, [tooltip]);

    return (
        <form id={classes["form-signin"]} onSubmit={handleSubmit}>
            {tooltip === true && (
                <div className={"alert " + classes["custom-alert-error"]} role="alert">
                    <span>{error}</span>
                </div>
            )}
            <TextField
                type="email"
                name="Email"
                id="login-email"
                placeholder={"Saisissez votre email"}
                value={userForm.email}
                onInput={(e) => setUserForm({ ...userForm, email: e.target.value })}
                required={true}
                className={classes["form-signin-input-email"]}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={faEnvelope} className='m-1' />
                }}
            />
            <TextField
                type={showPassword ? 'text' : 'password'}
                name="Mot de passe"
                id="login-password"
                placeholder={"Saisissez votre mot de passe"}
                onInput={(e) => setUserForm({ ...userForm, password: e.target.value })}
                required={true}
                className={classes["form-signin-input-password"]}
                InputProps={{
                    startAdornment: (<FontAwesomeIcon icon={faKey} className='m-1' />),
                    endAdornment: (<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} />)
                }}

            />
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? 'Connexion en cours...' : 'Connexion'}
            </button>
            <button type="button" className={"btn " + classes['form-signin-btn-recovery']} disabled={isLoading} onClick={() => passwordRecovery(true)}>
                Mot de passe oublié ?
            </button>
        </form>
    );
}