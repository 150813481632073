import React from 'react';
import './PasswordRecoveryForm.module.scss';
import classes from './PasswordRecoveryForm.module.scss.json';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';

export const PasswordRecoveryForm = ({ onSuccess = () => { } }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [userForm, setUserForm] = React.useState({
        email: ''
    });
    const [tooltip, showTooltip] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const req = await fetch('/api/customer/recovery-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userForm.email
                })
            });

            if (req.status === 200) {
                onSuccess(userForm.email);
            } else {
                if ([401, 403, 404].includes(req.status)) {
                    setError('Adresse email inconnue');
                } else {
                    setError('Une erreur est survenue');
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        if (error !== '') {
            showTooltip(true);
        }
    }, [error]);

    React.useEffect(() => {
        if (tooltip === false) {
            setError('');
        }
    }, [tooltip]);

    return (
        <form id={classes["form-recovery"]} onSubmit={handleSubmit}>
            {tooltip === true && (
                <div className={"alert " + classes["custom-alert-error"]} role="alert">
                    <span>{error}</span>
                </div>
            )}
            <TextField
                type="email"
                name="Email"
                id="login-email"
                placeholder={"Saisissez votre email"}
                value={userForm.email}
                onInput={(e) => setUserForm({ ...userForm, email: e.target.value })}
                required={true}
                className={classes["form-recovery-input-email"]}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={faEnvelope} className='m-1' />
                }}
            />
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? 'Veuillez patienter...' : 'Ré-initialiser votre mot de passe'}
            </button>
        </form>
    );
}